import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  getContacts,
  getSubscriptionInvitation,
  getVolunteerRecruitment,
  postEmailSubscription,
  postVolunteerApplication,
} from "../../constants/constants";

const Information = (props) => {
  const [facebook, setFacebook] = useState({ profile: "", url: "" });
  const [instagram, setInstagram] = useState({ profile: "", url: "" });
  const [email, setEmail] = useState({ profile: "", url: "" });
  const [subscriptionText, setSubscriptionText] = useState("");
  const [recruitmentText, setRecruitmentText] = useState("");

  const emailIsValid = (emailAddress) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress);

  const [subscriptionFlipToggler, setSubscriptionFlipToggler] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionEmail, setSubscriptionEmail] = useState("");
  const subscriptionFormIsValid = () =>
    subscriptionName && emailIsValid(subscriptionEmail);
  const [subSuccessful, setSubSuccessful] = useState(false);
  const [subFailed, setSubFailed] = useState(false);

  const [rerecruitmentFlipToggler, setRecruitmentFlipToggler] = useState(false);
  const [recruitmentName, setRecruitmentName] = useState("");
  const [recruitmentPhone, setRecruitmentPhone] = useState("");
  const [recruitmentEmail, setRecruitmentEmail] = useState("");
  const recruitmentFormIsValid = () =>
    recruitmentName && recruitmentPhone && emailIsValid(recruitmentEmail);
  const [appSuccessful, setAppSuccessful] = useState(false);
  const [appFailed, setAppFailed] = useState(false);

  useEffect(() => {
    getContent();
    getSubscriptionText();
    getRecruitmentText();
  }, []);

  const getContent = () => {
    fetch(getContacts)
      .then((res) => res.json())
      .then((data) => {
        setFacebook({
          ...facebook,
          profile: data.data
            .filter((item) => item.attributes.Platform === "facebook")
            .map((item) => item.attributes.Profile),
          url: data.data
            .filter((item) => item.attributes.Platform === "facebook")
            .map((item) => item.attributes.URL),
        });
        setInstagram({
          ...instagram,
          profile: data.data
            .filter((item) => item.attributes.Platform === "instagram")
            .map((item) => item.attributes.Profile),
          url: data.data
            .filter((item) => item.attributes.Platform === "instagram")
            .map((item) => item.attributes.URL),
        });
        setEmail({
          ...email,
          profile: data.data
            .filter((item) => item.attributes.Platform === "email")
            .map((item) => item.attributes.Profile),
          url: data.data
            .filter((item) => item.attributes.Platform === "email")
            .map((item) => item.attributes.URL),
        });
      })
      .catch((err) => console.log(err));
  };

  const getSubscriptionText = () => {
    fetch(getSubscriptionInvitation)
      .then((res) => res.json())
      .then((data) => setSubscriptionText(data.data.attributes.Message))
      .catch((err) => console.log(err));
  };

  const getRecruitmentText = () => {
    fetch(getVolunteerRecruitment)
      .then((res) => res.json())
      .then((data) => setRecruitmentText(data.data.attributes.Message))
      .catch((err) => console.log(err));
  };

  const clearSubscriptionForm = () => {
    setSubscriptionFlipToggler(false);
    setSubscriptionName("");
    setSubscriptionEmail("");
  };

  const clearRecruitmentForm = () => {
    setRecruitmentFlipToggler(false);
    setRecruitmentName("");
    setRecruitmentPhone("");
    setRecruitmentEmail("");
  };

  const handleSubscriptionSubmit = (e) => {
    e.preventDefault();
    fetch(postEmailSubscription, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          Name: subscriptionName,
          Email: subscriptionEmail,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSubSuccessful(true);
        clearSubscriptionForm();
      })
      .catch((err) => {
        setSubFailed(true);
        console.log(err);
      });
  };

  const handleRecruitmentSubmit = (e) => {
    e.preventDefault();
    fetch(postVolunteerApplication, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          Name: recruitmentName,
          Phone: recruitmentPhone,
          Email: recruitmentEmail,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAppSuccessful(true);
        clearRecruitmentForm();
      })
      .catch((err) => {
        setAppFailed(true);
        console.log(err);
      });
  };

  return (
    <section id="information" className="bg-milk py-8">
      <div className="content-wrapper">
        <div className="grid grid-cols-2 gap-8 max-md:grid-cols-1">
          <article
            className={`w-full h-[28rem] rounded-2xl form ${
              subscriptionFlipToggler ? "flip" : ""
            }`}
          >
            <div className="form-inner">
              <div className="form-side form-side--front">
                <div className="flex flex-col items-start justify-center w-full h-full px-8">
                  <h2 className="subtitle">追蹤我們</h2>
                  <ul className="flex flex-col items-start justify-center gap-4 font-medium text-lg mb-8">
                    <li>
                      <a
                        className="flex items-center justify-start gap-2 social-hover"
                        href={facebook.url}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="text-2xl"
                          icon={faFacebook}
                        />
                        <span>{facebook.profile}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center justify-start gap-2 social-hover"
                        href={instagram.url}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="text-2xl"
                          icon={faInstagram}
                        />
                        <span>{instagram.profile}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center justify-start gap-2 social-hover"
                        href={`mailto:${email.url}`}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="text-2xl"
                          icon={faEnvelope}
                        />
                        <span>{email.profile}</span>
                      </a>
                    </li>
                  </ul>
                  <p className="mb-8">{subscriptionText}</p>
                  <div className="grid grid-cols-[max-content_auto] gap-4">
                    <button
                      className="button-link bg-peach border-peach hover:text-peach"
                      onClick={() => setSubscriptionFlipToggler(true)}
                    >
                      訂閱表格
                    </button>
                    {subSuccessful && (
                      <span className="my-auto text-smoke font-medium">
                        訂閱成功！
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-side form-side--back">
                <div className="flex justify-center w-full h-full p-8">
                  <form
                    className="flex flex-col items-start justify-center gap-4 w-full"
                    onSubmit={handleSubscriptionSubmit}
                  >
                    <div className="w-full">
                      <label className="font-medium text-lg">姓名</label>
                      <small className="text-peach">（必填）</small>
                      <input
                        className="w-full h-10 my-2 px-2 text-lg bg-milk rounded-lg border-2 border-yellow"
                        type="text"
                        value={subscriptionName}
                        onChange={(e) => setSubscriptionName(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <label className="font-medium text-lg">電郵地址</label>
                      <small className="text-peach">（必填）</small>
                      <input
                        className="w-full h-10 my-2 px-2 text-lg bg-milk rounded-lg border-2 border-yellow"
                        type="email"
                        value={subscriptionEmail}
                        onChange={(e) => setSubscriptionEmail(e.target.value)}
                      />
                    </div>
                    <small className="text-smoke">
                      所有收集的個人資料將被保密，並只用於聯絡用途，不會向任何第三方披露。提交表格即表示同意條款。
                    </small>
                    <div className="grid grid-cols-[max-content_auto] gap-4">
                      <button
                        type="submit"
                        className="button-link bg-gamboge border-gamboge hover:text-gamboge disabled:opacity-50"
                        disabled={!subscriptionFormIsValid()}
                      >
                        立即訂閱
                      </button>
                      {subFailed && (
                        <span className="my-auto text-smoke font-medium">
                          訂閱失敗，請重試！
                        </span>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </article>
          <article
            className={`w-full h-[28rem] rounded-2xl form ${
              rerecruitmentFlipToggler ? "flip" : ""
            }`}
          >
            <div className="form-inner">
              <div className="form-side form-side--front">
                <div className="flex flex-col items-start justify-center w-full h-full px-8">
                  <h2 className="subtitle">義工招募</h2>
                  <p className="mb-8">{recruitmentText}</p>
                  <div className="grid grid-cols-[max-content_auto] gap-4">
                    <button
                      className="button-link bg-eton border-eton hover:text-eton"
                      onClick={() => setRecruitmentFlipToggler(true)}
                    >
                      報名表格
                    </button>
                    {appSuccessful && (
                      <span className="my-auto text-smoke font-medium">
                        報名成功！
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-side form-side--back">
                <div className="flex justify-center w-full h-full p-8">
                  <form
                    className="flex flex-col items-start justify-center gap-4 w-full"
                    onSubmit={handleRecruitmentSubmit}
                  >
                    <div className="w-full">
                      <label className="font-medium text-lg">姓名</label>
                      <small className="text-peach">（必填）</small>
                      <input
                        className="w-full h-10 my-2 px-2 text-lg bg-milk rounded-lg border-2 border-yellow"
                        type="text"
                        value={recruitmentName}
                        onChange={(e) => setRecruitmentName(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <label className="font-medium text-lg">聯絡電話</label>
                      <small className="text-peach">（必填）</small>
                      <input
                        className="w-full h-10 my-2 px-2 text-lg bg-milk rounded-lg border-2 border-yellow"
                        type="text"
                        value={recruitmentPhone}
                        onChange={(e) => setRecruitmentPhone(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <label className="font-medium text-lg">電郵地址</label>
                      <small className="text-peach">（必填）</small>
                      <input
                        className="w-full h-10 my-2 px-2 text-lg bg-milk rounded-lg border-2 border-yellow"
                        type="email"
                        value={recruitmentEmail}
                        onChange={(e) => setRecruitmentEmail(e.target.value)}
                      />
                    </div>
                    <small className="text-smoke">
                      所有收集的個人資料將被保密，並只用於義工報名及活動通知，不會向任何第三方披露。提交表格即表示同意條款。
                    </small>
                    <div className="grid grid-cols-[max-content_auto] gap-4">
                      <button
                        type="submit"
                        className="button-link bg-gamboge border-gamboge hover:text-gamboge disabled:opacity-50"
                        disabled={!recruitmentFormIsValid()}
                      >
                        提交表格
                      </button>
                      {appFailed && (
                        <span className="my-auto text-smoke font-medium">
                          報名失敗，請重試！
                        </span>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Information;
