import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faComment,
  faBookmark,
} from "@fortawesome/free-regular-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { cmsRoot, getHighlights, getReviews } from "../../constants/constants";
import ScrollContainer from "react-indiana-drag-scroll";

const Cards = (props) => {
  const [images, setImages] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getImages();
    getComments();
  }, []);

  const getImages = () => {
    fetch(getHighlights)
      .then((res) => res.json())
      .then((data) =>
        setImages(
          data.data.attributes.Image.data.map((item) => ({
            id: item.id,
            small: cmsRoot + item.attributes.formats.small.url,
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  const getComments = () => {
    fetch(getReviews)
      .then((res) => res.json())
      .then((data) =>
        setComments(
          data.data.map((item) => ({
            id: item.id,
            icon:
              cmsRoot +
              item.attributes.Icon.data.attributes.formats.thumbnail.url,
            name: item.attributes.Name,
            content: item.attributes.Comment,
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  return (
    <section id="cards" className="bg-cornsilk py-8">
      <div className="content-wrapper">
        <h2 className="subtitle">#社交傾 #SocialKingHK</h2>
        <ScrollContainer
          className="flex gap-4 p-8 overflow-x-auto z-10"
          hideScrollbars={false}
        >
          {images.map((image) => (
            <article className="card post" key={image.id}>
              <div className="overflow-hidden">
                <img
                  className="w-60 h-60 object-cover object-center"
                  src={image.small}
                  alt="Highlight"
                />
              </div>
              <div className="flex gap-2">
                <FontAwesomeIcon
                  className="text-xl image-hover"
                  icon={faHeart}
                />
                <FontAwesomeIcon
                  className="text-xl image-hover"
                  icon={faComment}
                />
              </div>
            </article>
          ))}
        </ScrollContainer>
        <ScrollContainer
          className="flex gap-4 p-4 overflow-x-auto"
          hideScrollbars={false}
        >
          {comments.map((comment) => (
            <article className="card" key={comment.id}>
              <div className="flex gap-2 items-center overflow-hidden">
                <img
                  className="w-8 h-8 rounded-full object-cover object-center"
                  src={comment.icon}
                  alt={comment.name}
                />
                <h3 className="font-medium">{comment.name}</h3>
              </div>
              <div className="w-96">
                <p>{comment.content}</p>
              </div>
              <div className="flex gap-2">
                <FontAwesomeIcon
                  className="text-xl image-hover"
                  icon={faRetweet}
                />
                <FontAwesomeIcon
                  className="text-xl image-hover"
                  icon={faBookmark}
                />
              </div>
            </article>
          ))}
        </ScrollContainer>
      </div>
    </section>
  );
};

export default Cards;
