import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { homePath, navLinks } from "../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import navLogo from "../../assets/social-king-logo-no-text.png";

const Header = (props) => {
  const [navActive, setNavActive] = useState(false);
  const navShownRef = useRef(null);
  const location = useLocation();

  const toggleNav = () => setNavActive(!navActive);
  const hideNav = () => setNavActive(false);

  return (
    <header
      id="header"
      className="sticky top-0 z-20 bg-cornsilk px-8 py-4 text-black text-base font-medium"
    >
      <nav
        id="navbar"
        className="flex items-center justify-between max-md:flex-col max-md:items-start"
      >
        <Link to={homePath}>
          <img className="h-10" src={navLogo} alt="Social King Logo - Small" />
        </Link>
        <Link className="absolute top-6 right-10 invisible nav-hover max-md:visible">
          {navActive ? (
            <FontAwesomeIcon
              className="text-2xl"
              icon={faChevronUp}
              onClick={toggleNav}
            />
          ) : (
            <FontAwesomeIcon
              className="text-2xl"
              icon={faBars}
              onClick={toggleNav}
            />
          )}
        </Link>
        <ul
          ref={navShownRef}
          className={`flex justify-center gap-4 duration-500 ${
            navActive ? "nav-shown" : "nav-hidden"
          }`}
        >
          {navLinks.map((navLink) => (
            <li key={navLink.label}>
              <Link
                className={`nav-hover ${
                  location.pathname == navLink.path ? "text-gamboge" : ""
                }`}
                to={navLink.path}
                onClick={hideNav}
              >
                {navLink.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
