import { useEffect, useState } from "react";
import { cmsRoot, getPartners } from "../../constants/constants";

const Brands = (props) => {
  const [brands, setBrands] = useState([]);

  useEffect(() => getBrands());

  const getBrands = () => {
    fetch(getPartners)
      .then((res) => res.json())
      .then((data) =>
        setBrands(
          data.data.map((item) => ({
            id: item.id,
            logo: cmsRoot + item.attributes.Logo.data.attributes.url,
            title: item.attributes.Title,
            url: item.attributes.URL,
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  return (
    <section id="brands" className="bg-milk py-8">
      <div className="content-wrapper">
        <h2 className="subtitle">合作機構</h2>
        <div className="grid grid-cols-4 gap-8 max-md:grid-cols-2">
          {brands.map((brand) => (
            <a
              className="flex item-center justify-center"
              href={brand.url}
              target="_blank"
              key={brand.id}
            >
              <img
                className="max-w-full object-contain object-center image-hover"
                src={brand.logo}
                alt={brand.Title}
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Brands;
