import Details from "./Details";
import ServiceHero from "./ServiceHero";

const Service = (props) => (
  <>
    <ServiceHero />
    <Details />
  </>
);

export default Service;
