import Gallery from "./Gallery";
import HighlightsHero from "./HighlightsHero";

const Highlights = (props) => (
  <>
    <HighlightsHero />
    <Gallery />
  </>
);

export default Highlights;
