import { useEffect, useState } from "react";
import { cmsRoot, getCourse } from "../../constants/constants";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const Details = (props) => {
  const [content, setContent] = useState({
    image: "",
    title: "",
    features: "",
    summary: "",
    content: "",
    url: "",
  });

  useEffect(() => getContent());

  const getContent = () => {
    fetch(getCourse)
      .then((res) => res.json())
      .then((data) =>
        setContent({
          ...content,
          image:
            cmsRoot +
            data.data.attributes.Image.data.attributes.formats.small.url,
          title: data.data.attributes.Title,
          features: data.data.attributes.Features,
          summary: data.data.attributes.Summary,
          content: data.data.attributes.Content,
          url: data.data.attributes.URL,
        })
      )
      .catch((err) => console.log(err));
  };

  return (
    <section id="details" className="bg-milk py-8">
      <div className="content-wrapper">
        <article className="grid grid-cols-2 gap-8 max-md:grid-cols-1 max-md:gap-0 py-4">
          <img
            className="w-full h-80 object-cover object-center rounded-lg shadow-lg image-hover"
            src={content.image}
            alt="服務詳情"
          />
          <div className=" flex flex-col items-start justify-center">
            <h2 className="subtitle">{content.title}</h2>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={rehypeRaw}
              children={content.features}
            />
          </div>
        </article>
        <article className="grid grid-cols-1 gap-8 py-8">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={rehypeRaw}
            children={content.summary}
          />
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={rehypeRaw}
            children={content.content}
          />
        </article>
        <a
          className="button-link bg-peach border-peach hover:text-peach"
          href={content.url}
          target="_blank"
        >
          立即報名
        </a>
      </div>
    </section>
  );
};

export default Details;
