// React Router paths

export const homePath = "/";
export const navLinks = [
  {
    label: "關於我們",
    slug: "about",
    path: "/about",
  },
  {
    label: "服務詳情",
    slug: "service",
    path: "/service",
  },
  {
    label: "活動花絮",
    slug: "highlights",
    path: "/highlights",
  },
  {
    label: "合作機構",
    slug: "partners",
    path: "/partners",
  },
  {
    label: "聯絡方式",
    slug: "contact",
    path: "/contact",
  },
];

// CMS endpoints

export const cmsRoot = "https://socialking.cyruscsc.com/cms";
export const getReviews = `${cmsRoot}/api/reviews?populate=Icon&fields[0]=Name&fields[1]=Comment`;
export const getAbouts = `${cmsRoot}/api/abouts?populate=Image&fields[0]=Slug&fields[1]=Title&fields[2]=Content`;
export const getCourse = `${cmsRoot}/api/course?populate=Image&fields[0]=Title&fields[1]=Features&fields[2]=Summary&fields[3]=Content&fields[4]=URL`;
export const getHighlights = `${cmsRoot}/api/highlight?populate=Image`;
export const getPartners = `${cmsRoot}/api/partners?populate=Logo&fields[0]=Title&fields[1]=Description&fields[2]=URL`;
export const getContacts = `${cmsRoot}/api/contacts?fields[0]=Platform&fields[1]=Profile&fields[2]=URL`;
export const getSubscriptionInvitation = `${cmsRoot}/api/subscription-invitation?fields[0]=Message`;
export const getVolunteerRecruitment = `${cmsRoot}/api/volunteer-recruitment?fields[0]=Message`;
export const getHomeWallpaper = `${cmsRoot}/api/page-wallpapers?populate=Wallpaper&fields[0]=Title&filters[Slug]=home`;
export const getAboutWallpaper = `${cmsRoot}/api/page-wallpapers?populate=Wallpaper&fields[0]=Title&filters[Slug]=about`;
export const getServiceWallpaper = `${cmsRoot}/api/page-wallpapers?populate=Wallpaper&fields[0]=Title&filters[Slug]=service`;
export const getHighlightsWallpaper = `${cmsRoot}/api/page-wallpapers?populate=Wallpaper&fields[0]=Title&filters[Slug]=highlights`;
export const getPartnersWallpaper = `${cmsRoot}/api/page-wallpapers?populate=Wallpaper&fields[0]=Title&filters[Slug]=partners`;
export const getContactWallpaper = `${cmsRoot}/api/page-wallpapers?populate=Wallpaper&fields[0]=Title&filters[Slug]=contact`;
export const postEmailSubscription = `${cmsRoot}/api/email-subscriptions`;
export const postVolunteerApplication = `${cmsRoot}/api/volunteer-applications`;
