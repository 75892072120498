import { useEffect, useState } from "react";
import { cmsRoot, getHighlightsWallpaper } from "../../constants/constants";

const HighlightsHero = (props) => {
  const [title, setTitle] = useState("活動花絮");
  const [wallpaper, setWallpaper] = useState("");

  useEffect(() => getWallpaper(), []);

  const getWallpaper = () => {
    fetch(getHighlightsWallpaper)
      .then((res) => res.json())
      .then((data) => {
        setTitle(data.data[0].attributes.Title);
        setWallpaper(
          cmsRoot +
            data.data[0].attributes.Wallpaper.data.attributes.formats.large.url
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <section id="highlights-hero" className="bg-cornsilk">
      <div className="w-full h-[18rem] absolute">
        <img
          className="w-full h-full object-cover object-center opacity-30"
          src={wallpaper}
          alt="Wallpaper"
        />
      </div>
      <div className="content-wrapper flex py-4 items-end justify-start w-full h-[18rem] text-center text-black relative z-10">
        <h1 className="title">{title}</h1>
      </div>
    </section>
  );
};

export default HighlightsHero;
