import { useEffect, useState } from "react";
import { cmsRoot, getPartners } from "../../constants/constants";

const PartnersList = (props) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => getCompanies(), []);

  const getCompanies = () => {
    fetch(getPartners)
      .then((res) => res.json())
      .then((data) =>
        setCompanies(
          data.data.map((item) => ({
            id: item.id,
            logo: cmsRoot + item.attributes.Logo.data.attributes.url,
            title: item.attributes.Title,
            description: item.attributes.Description,
            url: item.attributes.URL,
          }))
        )
      )
      .catch((err) => console.log(err));
  };
  return (
    <section id="partners-list" className="bg-milk py-8">
      <div className="content-wrapper">
        <div className="flex flex-col gap-16 py-8">
          {companies.map((company) => (
            <article className="grid grid-cols-2 gap-8 max-md:grid-cols-1 max-md:gap-2">
              <div className="flex items-center justify-center">
                <img
                  className="w-1/2 image-hover"
                  src={company.logo}
                  alt={company.title}
                />
              </div>
              <div className="flex flex-col items-start max-md:items-center justify-center gap-2">
                <a href={company.url} target="_blank">
                  <h3 className="section-heading">{company.title}</h3>
                </a>
                <p>{company.description}</p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersList;
