import { Routes, Route, Navigate } from "react-router-dom";
import { homePath, navLinks } from "./constants/constants";
import Base from "./components/base/Base";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Service from "./components/service/Service";
import Highlights from "./components/highlights/Highlights";
import Partners from "./components/partners/Partners";
import Contact from "./components/contact/Contact";

const App = () => {
  return (
    <Base>
      <Routes>
        <Route path={homePath} element={<Home />} />
        <Route
          path={
            navLinks
              .filter((navLink) => navLink.slug === "about")
              .map((navLink) => navLink.path)[0]
          }
          element={<About />}
        />
        <Route
          path={
            navLinks
              .filter((navLink) => navLink.slug === "service")
              .map((navLink) => navLink.path)[0]
          }
          element={<Service />}
        />
        <Route
          path={
            navLinks
              .filter((navLink) => navLink.slug === "highlights")
              .map((navLink) => navLink.path)[0]
          }
          element={<Highlights />}
        />
        <Route
          path={
            navLinks
              .filter((navLink) => navLink.slug === "partners")
              .map((navLink) => navLink.path)[0]
          }
          element={<Partners />}
        />
        <Route
          path={
            navLinks
              .filter((navLink) => navLink.slug === "contact")
              .map((navLink) => navLink.path)[0]
          }
          element={<Contact />}
        />
        <Route path="*" element={<Navigate to={homePath} />} />
      </Routes>
    </Base>
  );
};

export default App;
