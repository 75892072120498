import HomeHero from "./HomeHero";
import Course from "./Course";
import Cards from "./Cards";
import Brands from "./Brands";

const Home = (props) => (
  <>
    <HomeHero />
    <Course />
    <Cards />
    <Brands />
  </>
);

export default Home;
