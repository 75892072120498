import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  navLinks,
  cmsRoot,
  getHomeWallpaper,
  getAbouts,
} from "../../constants/constants";
import heroLogo from "../../assets/social-king-logo.png";

const HomeHero = (props) => {
  const [wallpaper, setWallpaper] = useState("");
  const [vision, setVision] = useState("");

  useEffect(() => {
    getWallpaper();
    getContent();
  }, []);

  const getWallpaper = () => {
    fetch(getHomeWallpaper)
      .then((res) => res.json())
      .then((data) =>
        setWallpaper(
          cmsRoot +
            data.data[0].attributes.Wallpaper.data.attributes.formats.large.url
        )
      )
      .catch((err) => console.log(err));
  };

  const getContent = () => {
    fetch(getAbouts)
      .then((res) => res.json())
      .then((data) => {
        setVision(
          data.data
            .filter((item) => item.attributes.Slug === "vision")
            .map((item) => item.attributes.Content)
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <section id="home-hero" className="bg-cornsilk">
      <div className="w-full h-[30rem] absolute">
        <img
          className="w-full h-full object-cover object-center opacity-30"
          src={wallpaper}
          alt="Wallpaper"
        />
      </div>
      <article className="home-flex-vertical items-center justify-end w-full h-[30rem] text-center text-black text-base font-normal relative z-10">
        <img
          className="h-40 image-hover"
          src={heroLogo}
          alt="Social King Logo"
        />
        <p className="w-1/2">{vision}</p>
        <Link
          className="button-link bg-gamboge border-gamboge hover:text-gamboge"
          to={
            navLinks
              .filter((navLink) => navLink.slug === "about")
              .map((navLink) => navLink.path)[0]
          }
        >
          關於我們
        </Link>
      </article>
    </section>
  );
};

export default HomeHero;
