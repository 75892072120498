import PartnersHero from "./PartnersHero";
import PartnersList from "./PartnersList";

const Partners = (props) => (
  <>
    <PartnersHero />
    <PartnersList />
  </>
);

export default Partners;
