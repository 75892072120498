import AboutHero from "./AboutHero";
import AboutUs from "./AboutUs";

const About = (props) => (
  <>
    <AboutHero />
    <AboutUs />
  </>
);

export default About;
