import { useEffect, useState } from "react";
import { cmsRoot, getAbouts } from "../../constants/constants";

const AboutUs = (props) => {
  const [story, setStory] = useState({ image: "", title: "", content: "" });
  const [vision, setVision] = useState({ image: "", title: "", content: "" });
  const [mission, setMission] = useState({ image: "", title: "", content: "" });

  useEffect(() => getContent(), []);

  const getContent = () => {
    fetch(getAbouts)
      .then((res) => res.json())
      .then((data) => {
        setStory({
          ...story,
          image:
            cmsRoot +
            data.data
              .filter((item) => item.attributes.Slug === "story")
              .map(
                (item) =>
                  item.attributes.Image.data.attributes.formats.small.url
              ),
          title: data.data
            .filter((item) => item.attributes.Slug === "story")
            .map((item) => item.attributes.Title),
          content: data.data
            .filter((item) => item.attributes.Slug === "story")
            .map((item) => item.attributes.Content),
        });
        setVision({
          ...vision,
          image:
            cmsRoot +
            data.data
              .filter((item) => item.attributes.Slug === "vision")
              .map(
                (item) =>
                  item.attributes.Image.data.attributes.formats.small.url
              ),
          title: data.data
            .filter((item) => item.attributes.Slug === "vision")
            .map((item) => item.attributes.Title),
          content: data.data
            .filter((item) => item.attributes.Slug === "vision")
            .map((item) => item.attributes.Content),
        });
        setMission({
          ...mission,
          image:
            cmsRoot +
            data.data
              .filter((item) => item.attributes.Slug === "mission")
              .map(
                (item) =>
                  item.attributes.Image.data.attributes.formats.small.url
              ),
          title: data.data
            .filter((item) => item.attributes.Slug === "mission")
            .map((item) => item.attributes.Title),
          content: data.data
            .filter((item) => item.attributes.Slug === "mission")
            .map((item) => item.attributes.Content),
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <section id="about-us" className="bg-milk py-8">
      <div className="content-wrapper">
        <article className="grid grid-cols-2 gap-8 max-md:grid-cols-1 max-md:gap-0 py-4">
          <div className="rounded-lg overflow-hidden shadow-lg">
            <img
              className="w-full h-80 object-cover object-center image-hover"
              src={story.image}
              alt="Our Story"
            />
          </div>
          <div className="about-text-card items-start bg-yellow">
            <h2 className="subtitle">{story.title}</h2>
            <p>{story.content}</p>
          </div>
        </article>
        <article className="grid grid-cols-2 gap-8 max-md:grid-cols-1 max-md:gap-0 py-4">
          <div className="about-text-card items-end bg-eton">
            <h2 className="subtitle">{vision.title}</h2>
            <p>{vision.content}</p>
          </div>
          <div className="rounded-lg overflow-hidden shadow-lg">
            <img
              className="w-full h-80 object-cover object-center image-hover"
              src={vision.image}
              alt="Our Story"
            />
          </div>
        </article>
        <article className="grid grid-cols-2 gap-8 max-md:grid-cols-1 max-md:gap-0 py-4">
          <div className="rounded-lg overflow-hidden shadow-lg">
            <img
              className="w-full h-80 object-cover object-center image-hover"
              src={mission.image}
              alt="Our Story"
            />
          </div>
          <div className="about-text-card items-start bg-peach">
            <h2 className="subtitle">{mission.title}</h2>
            <p>{mission.content}</p>
          </div>
        </article>
      </div>
    </section>
  );
};

export default AboutUs;
