import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { cmsRoot, getHighlights } from "../../constants/constants";
import FsLightbox from "fslightbox-react";

const Gallery = (props) => {
  const [images, setImages] = useState([]);
  const [hiddenToggler, setHiddenToggle] = useState(true);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  useEffect(() => getImages(), []);

  const getImages = () => {
    fetch(getHighlights)
      .then((res) => res.json())
      .then((data) =>
        setImages(
          data.data.attributes.Image.data.map((item) => ({
            id: item.id,
            small: cmsRoot + item.attributes.formats.small.url,
            large: cmsRoot + item.attributes.formats.large.url,
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  const openLightbox = (slideNum) => {
    setLightboxController({
      ...lightboxController,
      toggler: !lightboxController.toggler,
      slide: slideNum,
    });
    console.log(slideNum);
  };
  return (
    <section id="gallery" className="bg-milk">
      <div className="content-wrapper py-8">
        <div
          className={`flex flex-wrap gap-4 justify-evenly w-full p-4 ${
            hiddenToggler ? "h-[28rem] overflow-hidden" : "h-auto"
          }`}
        >
          {images.map((image, index) => (
            <img
              className="w-64 h-32 object-cover object-center shadow-lg rounded-lg image-hover"
              src={image.small}
              alt="Event Highlight"
              key={image.id}
              onClick={(e) => openLightbox(index + 1)}
            />
          ))}
          <FsLightbox
            toggler={lightboxController.toggler}
            slide={lightboxController.slide}
            sources={images.map((image) => image.large)}
          />
        </div>
        <div className="flex items-center justify-center py-4">
          <button
            className="flex gap-2 items-center justify-center text-gamboge font-normal text-xl hover:scale-110 duration-300"
            onClick={() => setHiddenToggle(!hiddenToggler)}
          >
            <span>{hiddenToggler ? "顯示更多" : "收起"}</span>
            {hiddenToggler ? (
              <FontAwesomeIcon icon={faArrowDown} />
            ) : (
              <FontAwesomeIcon icon={faArrowUp} />
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
