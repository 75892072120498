import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { navLinks, cmsRoot, getCourse } from "../../constants/constants";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const Course = (props) => {
  const [content, setContent] = useState({ image: "", title: "", summary: "" });

  useEffect(() => getContent());

  const getContent = () => {
    fetch(getCourse)
      .then((res) => res.json())
      .then((data) =>
        setContent({
          ...content,
          image:
            cmsRoot +
            data.data.attributes.Image.data.attributes.formats.small.url,
          title: data.data.attributes.Title,
          summary: data.data.attributes.Summary,
        })
      )
      .catch((err) => console.log(err));
  };

  return (
    <section id="course" className="bg-milk py-8">
      <div className="content-wrapper">
        <h2 className="subtitle">課程簡介</h2>
        <div className="grid grid-cols-2 gap-8 max-md:grid-cols-1 max-md:gap-0">
          <div className="flex items-center justify-center">
            <img
              className="w-full h-80 rounded-2xl object-cover object-center image-hover hover:shadow-lg"
              src={content.image}
              alt={content.title}
            />
          </div>
          <article className="home-flex-vertical items-start text-start">
            <h3 className="section-heading">{content.title}</h3>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={rehypeRaw}
              children={content.summary}
            />
            <Link
              className="button-link bg-peach border-peach hover:text-peach"
              to={
                navLinks
                  .filter((navLink) => navLink.slug === "service")
                  .map((navLink) => navLink.path)[0]
              }
            >
              了解更多
            </Link>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Course;
