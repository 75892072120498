import ContactHero from "./ContactHero";
import Information from "./Information";

const Contact = (props) => (
  <>
    <ContactHero />
    <Information />
  </>
);

export default Contact;
