import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { getContacts } from "../../constants/constants";

const Footer = (props) => {
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");

  useEffect(() => getUrls(), []);

  const getUrls = () => {
    fetch(getContacts)
      .then((res) => res.json())
      .then((data) => {
        setFacebook(
          data.data
            .filter((item) => item.attributes.Platform === "facebook")
            .map((item) => item.attributes.URL)
        );
        setInstagram(
          data.data
            .filter((item) => item.attributes.Platform === "instagram")
            .map((item) => item.attributes.URL)
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <footer
      id="footer"
      className="bg-smoke text-white grid grid-cols-1 gap-4 text-center py-8"
    >
      <div className="flex justify-center gap-4 text-2xl">
        <a className="social-hover" href={facebook} target="_blank">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a className="social-hover" href={instagram} target="_blank">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
      <div>
        <p className="text-xs">© 2023 Social King 社交．傾</p>
      </div>
    </footer>
  );
};

export default Footer;
